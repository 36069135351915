import { CircularProgress, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { useConfig } from '../hooks/AppProvider';

const Loading = ({
  w = '100%',
  h = 0,
}) => {
  const { refs } = useConfig();
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    setHeight(refs.getTotalHeight());
  }, [refs]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width={w}
      height={h || `calc(100vh - ${height}px)`}
    >
      <CircularProgress sx={{ bgColor: 'transparent' }} />
    </Stack>
  );
};

Loading.propTypes = {
  h: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  w: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default Loading;
