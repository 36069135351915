export const onClick = (e, navigateTo) => {
  // Ignore canceled events, modified clicks, and right clicks.
  if (e.defaultPrevented) return;

  if (e.metaKey || e.ctrlKey || e.shiftKey) return;

  if (e.button !== 0) return;

  // Get the <a> element.
  let el = e.target;
  while (el && el.nodeName !== 'A') {
    el = el.parentNode;
  }

  // Ignore clicks from non-a elements.
  if (!el) return;

  // Ignore the click if the element has a target.
  if (el.target && el.target !== '_self') return;

  // Ignore the click if it's a download link. (We use this method of
  // detecting the presence of the attribute for old IE versions.)
  if (el.attributes.download) return;

  // Ignore 'rel="external"' links.
  if (el.rel && /(?:^|\s+)external(?:\s+|$)/.test(el.rel)) return;

  // Get the href
  const href = el.getAttribute('href');

  // Ignore hash (used often instead of javascript:void(0) in strict CSP envs)
  if (href === '#') return;

  if (href.startsWith('http')) return;
  if (href.startsWith('mailto')) return;
  if (href.startsWith('tel')) return;

  // Prevent :focus from sticking; preventDefault() stops blur in some browsers
  el.blur();
  e.preventDefault();

  window.setTimeout(() => {
    // scroll to top on location change
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 50);

  navigateTo(href);
};
