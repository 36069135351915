import { Box, Button, Checkbox, Dialog, DialogContent, Fade, FormControlLabel, FormGroup, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCookies } from '../hooks/CookieProvider';

const StyledContent = styled(DialogContent)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.background.darkblue,
  color: 'white',
  h2: {
    margin: '1rem 0',
  },
  '.MuiButtonBase-root': {
    color: 'white !important',
  },
  '.MuiFormControlLabel-label.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
  '.Mui-disabled': {
    color: 'rgba(255, 255, 255, 0.5) !important',
  },
  [theme.breakpoints.up('md')]: {
    position: 'fixed',
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    top: 'auto',
    bottom: 0,
    flexDirection: 'row',
    padding: '2rem',
    h2: {
      margin: 0,
    },
    '.infoBox': {
      maxWidth: '80%',
    },
  },
}));

const StyledButtonContainer = styled(Box)({
  maxWidth: '600px',
  borderTop: '1px solid rgba(255, 255, 255, 0.5)',
  marginTop: '1.5rem',
  paddingTop: '0.5rem',
  button: {
    margin: '0 0.5rem 0 0',
    padding: '0 0.5rem',
    textTransform: 'none',
    borderLeft: '1px solid rgba(255, 255, 255, 0.5)',
    borderRight: '1px solid rgba(255, 255, 255, 0.5)',
  },
  'button:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const StyledTableContainer = styled(TableContainer)({
  marginBottom: '1rem',
  backgroundColor: 'rgba(255, 255, 255, 0.2);',
  '.MuiTableCell-root': {
    color: 'white',
  },
});

const ActionButtons = ({ setShowCookieDetails }) => {
  const { t } = useTranslation();
  const cookieConsent = useCookies();
  const { acceptAllCookies, declineCookies, saveConsent } = cookieConsent;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        fullWidth
        size='large'
        variant="contained"
        onClick={() =>  { setShowCookieDetails(false); acceptAllCookies(); }}
        sx={{ mb: '0.5rem', backgroundColor: 'rgba(255, 255, 255, 0.4)' }}
      >
        {t('cookieConsent.button.accept')}
      </Button>
      <Button
        fullWidth
        size='large'
        variant="contained"
        onClick={() =>  { setShowCookieDetails(false); declineCookies(); }}
        sx={{ mb: '0.5rem', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      >
        {t('cookieConsent.button.decline')}
      </Button>
      <Button
        fullWidth
        size="large"
        variant="contained"
        onClick={() => { setShowCookieDetails(false); saveConsent(); }}
        sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
      >
        {t('cookieConsent.button.save')}
      </Button>
    </Box>
  );
};

ActionButtons.propTypes = {
  setShowCookieDetails: PropTypes.func.isRequired,
};

const CookieTable = ({ cookies }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const fixed150 = { width: !mobile ? 150 : 'auto' };
  const fixed250 = { width: !mobile ? 250 : 'auto' };
  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="cookie table">
        <TableHead>
          <TableRow>
            <TableCell sx={fixed150}>{t('cookieConsent.table.name')}</TableCell>
            <TableCell sx={fixed250}>{t('cookieConsent.table.provider')}</TableCell>
            <TableCell>{t('cookieConsent.table.purpose')}</TableCell>
            <TableCell sx={fixed150}>{t('cookieConsent.table.validity')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cookies.length && cookies.map((cookie) => (
            <TableRow
              key={cookie.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{cookie.name}</TableCell>
              <TableCell>{cookie.provider}</TableCell>
              <TableCell>{t(`cookieConsent.cookies.${cookie.key}.purpose`)}</TableCell>
              <TableCell>{t(`cookieConsent.cookies.${cookie.key}.validity`)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
    );
}

CookieTable.propTypes = {
  cookies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const CookieConsent = () => {
  const cookieConsent = useCookies();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showCookieDetails, setShowCookieDetails] = React.useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAccepted, saveConsent, showCookieConsent, toggleCookieGroup } = cookieConsent;

  const isLegalInfo = () => {
    let check = false;
    ['impressum', 'imprint', 'datenschutz', 'privacypolicy'].forEach((path) => {
      if (location.pathname.includes(path)) {
        check = true;
      }
    });
    return check;
  };

  return showCookieConsent && !isLegalInfo() && (
    <Dialog
      open={showCookieConsent}
      onClose={saveConsent}
      fullScreen={mobile}
    >
      <Fade in={showCookieConsent}>
        <StyledContent>
          <Box className="infoBox">
            <h2>{t('cookieConsent.title')}</h2>
            <Typography>{t('cookieConsent.info')}</Typography>
            <FormGroup
              sx={{
                margin: '1rem 0',
                display: 'flex',
                flexDirection: showCookieDetails ? 'column' : 'row',
              }}
            >
              <FormControlLabel disabled control={<Checkbox defaultChecked />} label={t('cookieConsent.group.essential')} />
              {showCookieDetails && <CookieTable cookies={[
                {
                  key: 'i18next',
                  name: 'i18nextLng',
                  provider: 'rippon-boswell-wiesbaden.de',
                },
                {
                  key: 'hideCookieConsent',
                  name: 'mk.hcc',
                  provider: 'rippon-boswell-wiesbaden.de',
                },
                {
                  key: 'acceptedCookieGroups',
                  name: 'mk.acg',
                  provider: 'rippon-boswell-wiesbaden.de',
                }
              ]} />}
              <FormControlLabel control={<Checkbox onChange={() => { toggleCookieGroup('functional'); }} checked={isAccepted('functional')} />} label={t('cookieConsent.group.functional')} />
              {showCookieDetails && <CookieTable cookies={[
                {
                  key: 'hcaptcha',
                  name: 'session',
                  provider: 'hcaptcha.com',
                }
              ]} />}
              <FormControlLabel control={<Checkbox onChange={() => { toggleCookieGroup('statistics'); }} checked={isAccepted('statistics')} />} label={t('cookieConsent.group.statistics')} />
              {showCookieDetails && <CookieTable cookies={[
                {
                  key: 'pkSess',
                  name: '_pk.ses.*',
                  provider: 'rippon-boswell-wiesbaden.de',
                },
                {
                  key: 'pkId',
                  name: '_pk.id.*',
                  provider: 'rippon-boswell-wiesbaden.de',
                }
              ]} />}
            </FormGroup>
            {mobile && <ActionButtons setShowCookieDetails={setShowCookieDetails} />}
            <StyledButtonContainer>
              <Button
                variant="text"
                onClick={() => { setShowCookieDetails(!showCookieDetails); }}
              >
                {t(`cookieConsent.button.${showCookieDetails ? 'lessDetails' : 'moreDetails'}`)}
              </Button>
              <Button
                variant="text"
                onClick={() => { navigate(t('cookieConsent.button.imprintLink')); }}
              >
                {t('cookieConsent.button.imprint')}
              </Button>
              <Button
                variant="text"
                onClick={() => { navigate(t('cookieConsent.button.privacyPolicyLink')); }}
              >
                {t('cookieConsent.button.privacyPolicy')}
              </Button>
            </StyledButtonContainer>
          </Box>
          {!mobile && <ActionButtons setShowCookieDetails={setShowCookieDetails} />}
        </StyledContent>
      </Fade>
    </Dialog>
  );
}

export default CookieConsent;
