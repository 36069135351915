import moment from 'moment';

import i18n from '../i18n';
import { getTranslation } from './common';

const isLocal = process.env.REACT_APP_LOCAL;

// TODO: get path parts from menu
export const getCatalogURL = (auction) => {
  if (moment(auction.date).isAfter(moment())) {
    return i18n.getLang() === 'en'
      ? '/catalogue/online'
      : '/katalog/online'
  }
  return i18n.getLang() === 'en'
    ? `/archive/${auction.number}`
    : `/archiv/${auction.number}`;
}

export const getLotNumber = (type, lot) => {
  const auctionNumber = lot?.auction?.number || '';
  if (auctionNumber) {
    return `A${auctionNumber.replace(/^0+/, '')}/${lot?.number}`;
  }
  return lot?.number;
}

export const getAuctionNumber = (auction) => auction?.number ? `A${auction.number.replace(/^0+/, '')}` : '';

export const getPriceLabel = (auction, type, options) => {
  const suffix = options?.currency ? 'In' : '';
  if (type === 'aftersale') {
    return i18n.t(`lots.limit${suffix}`, options);
  }
  if (type === 'highlights') {
    const priceDisplay = auction.priceDisplayHighlight === 'hammerprice' ? 'surcharge' : 'result';
    return i18n.t(`lots.${priceDisplay}${suffix}`, options);
  }
  if (type === 'results') {
    const priceDisplay = auction.priceDisplayResult === 'hammerprice' ? 'surcharge' : 'result';
    return i18n.t(`lots.${priceDisplay}${suffix}`, options);
  }
  return i18n.t(`lots.estimate${suffix}`, options);
}

export const getPrice = (auction, type, lot, options) => {
  if (!lot) return '';
  const price = [];
  switch (type) {
    case 'aftersale':
      price.push(i18n.currency(lot.limit, { decimals: 0, ...options }));
      break;
    case 'highlights':
      price.push(i18n.currency(auction.priceDisplayHighlight === 'hammerprice' ? lot.surcharge : lot.result, { decimals: 0, ...options }));
      break;
    case 'results':
      price.push(i18n.currency(auction.priceDisplayResult === 'hammerprice' ? lot.surcharge : lot.result, { decimals: 0, ...options }));
      break;
    case 'archive':
    case 'online-catalog':
    default:
      if (lot.estimate) price.push(i18n.currency(lot.estimate, { decimals: 0, ...options }));
      if (lot.estimate && lot.highestimate) price.push(' - ');
      if (lot.highestimate) price.push(i18n.currency(lot.highestimate, { decimals: 0, ...options }));
  }
  return price.join('');
}

export const getOriginLabel = (origin) => {
  const region = getTranslation(origin, 'region');
  const category = getTranslation(origin, 'category');
  return `${region || ''}${(region && category && ', ') || ''}${category || ''}`
}

export const localizeImagePath = (path) => isLocal ? path.replace('https://cdn.rippon-boswell-wiesbaden.de', 'http://localhost') : path;