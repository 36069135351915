import axios from 'axios';
import localforage from 'localforage';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
const cacheExpiry = moment(process.env.REACT_APP_CACHE_EXPIRY);

const CacheContext = React.createContext({});

const useCache = () => React.useContext(CacheContext);

const CacheProvider = ({ children = null }) => {
  const listeners = {};

  const fetchCache = async () => {
    const cacheValue = await get('cache')?.value;
    await set('cache', cacheValue);
    const response = await axios.get(`${api_endpoint}/cache`);
    await set('cache', response.data);
    Object.entries(listeners).forEach(([endpoint, callbacks]) => {
      const oldDate = cacheValue?.find((e) => e.endpoint === endpoint)?.updatedAt || '';
      const newDate = response.data?.find((e) => e.endpoint === endpoint)?.updatedAt || '';
      if (oldDate !== newDate) {
        callbacks.forEach((c) => c());
      }
    });
  }

  const get = (name) => localforage.getItem(name);

  const set = (name, value) => {
    localforage.setItem(
      name,
      {
        ts: moment().toISOString(),
        value
      }
    );
  };

  const clearOldStorage = () => {
    Object.keys(localStorage).forEach((key) => {
      if (!['acceptedCookieGroups', 'hideCookieConsent', 'i18nextLng'].includes(key)) {
        localStorage.removeItem(key);
      }
    });
  };

  const isStale = async (endpoint, params) => {
    if (!endpoint) return true;
    const store = await get((params) ? `${endpoint}/${JSON.stringify(params)}` : endpoint);
    if (!store) return true;
    if (moment(store.ts).isBefore(cacheExpiry)) return true;
    const cacheState = await get('cache');
    const lastUpdate = cacheState?.value?.find((c) => c.endpoint === endpoint);
    if (!lastUpdate) {
      return moment(store.ts)
        .add(1, 'day')
        .isBefore(moment());
    }
    return moment(store.ts)
      .isBefore(moment(lastUpdate.updatedAt));
  }

  const fetchData = async (setData, setLoading, endpoint, params, controller) => {
    setLoading(true);
    let aborted = false;
    if (controller) {
      controller.signal.addEventListener('abort', () => { aborted = true; });
    }
    if (aborted) return;
    const cacheTag = (params) ? `${endpoint}/${JSON.stringify(params)}` : endpoint;
    const store = await get(cacheTag);
    if (store) {
      if (aborted) return;
      setData(store.value || []);
    }
    const cacheState = await get('cache');
    if (!cacheState || moment(cacheState.ts).add(30, 'seconds').isBefore(moment())) {
      await fetchCache();
    }
    if (await isStale(endpoint, params)) {
      const response = await axios.get(`${api_endpoint}/${endpoint}`, { params });
      if (aborted) return;
      setData(response.data);
      await set(cacheTag, response.data);
    }
    if (aborted) return;
    setLoading(false);
  }

  const on = (endpoint, callback) => {
    if (!listeners[endpoint]) {
      listeners[endpoint] = [];
    }
    listeners[endpoint].push(callback);
  }

  React.useEffect(() => {
    if (localStorage.getItem('cache')) clearOldStorage();
  }, []);

  const cache = { fetchData, get, on, set };

  return (
    <CacheContext.Provider value={cache}>
      {children}
    </CacheContext.Provider>
  )
};

CacheProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { CacheProvider, useCache };
