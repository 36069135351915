import PropTypes from 'prop-types';
import React from "react";

import { useCache } from "./CacheProvider";

const AppContext = React.createContext(null);

const useConfig = () => {
  const { config, loading, refs } = React.useContext(AppContext);

  return { config, loading, refs };
};

// eslint-disable-next-line react/display-name
const withConfig = (Component) => (props) => {
  const config = useConfig();

  return <Component {...props} config={config} />;
};

const AppProvider = ({ children = null }) => {
  const cache = useCache();
  const [config, setConfig] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const catalogTop = React.useRef();
  const head = React.useRef();
  const nav = React.useRef();
  const title = React.useRef();

  const getHeight = (ref) => ref?.current?.offsetHeight || 0;

  const getTop = (ref) => ref?.current?.offsetTop || 0;

  const getTotalHeight = () => getHeight(head) + getHeight(nav) + getHeight(title);

  const refs = {
    catalogTop,
    head,
    getHeight,
    getTop,
    getTotalHeight,
    nav,
    title,
  };

  React.useEffect(() => {
    const controller = new AbortController();
    cache.fetchData(setConfig, setLoading, 'setup', null, controller);
    cache.on('setup', () => {
      cache.fetchData(setConfig, setLoading, 'setup', null, controller);
    });
    return () => controller.abort();
  }, []);

  return (
    <AppContext.Provider value={{ config, loading, refs }}>
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export { AppProvider, useConfig, withConfig };

